import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    MacroContextMenu.init();
    DatePicker.init();
    Tooltipster.init();
    ResetFoundation.init();
    ConsultNote.init();
    StandardJs.init();
    Combobox.init();
    ReassignImportedFiles.init();
    TextGenerator.init($('.consult-data-window'));  
  }

}