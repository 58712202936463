import { Controller } from "@hotwired/stimulus"
import RequestController from "@components/utilities/request_controller"

export default class extends Controller {
  static targets = ['row'];
  navigate(event) {
    event.preventDefault();
    const url = this.rowTarget.getAttribute('data-href');
    const remote = this.rowTarget.getAttribute('data-remote') === 'true';
    const type = this.rowTarget.getAttribute('data-type') || 'GET';
    const turbo = this.rowTarget.getAttribute('data-turbo') === 'true';
    const turbo_frame = this.rowTarget.getAttribute('data-turbo-frame')

    if (url) {
      if (remote) {
        $.ajax({
          type: type.toUpperCase(),
          url: url,
          dataType: 'script',
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          }
        });
      } else if (turbo) {
        const request = new RequestController();
        request.fetchTurboFrame({ params: { url: url, turbo_frame: turbo_frame } })
          //   .then(response => {
          //     this.skillCodes = response;
          //     this.updateSkillCode(this.element.value);
          //   })
          // fetchTurboStream({ params: { url: url } });
      } else {
        window.location.href = url;
      }
    }
  }
}